<template><v-app><Header /><v-main><v-container fluid>

</v-container></v-main><Footer /></v-app></template>
<script>
import Header from './layout/Header';
import Footer from './layout/Footer';

  export default {
    components: { Header, Footer }    
  }
</script>
